export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function priceCalculator(service, persons) {
	let baseprice = service.payments.price;
	let x = persons - 2;
	let price = (-5/6 * Math.pow(x, 3) + 7 * Math.pow(x, 2) - 121/6 * x + 14 + parseFloat(baseprice)).toFixed(2) * persons;
	if (persons === 7) {
		return service.name.toLowerCase().includes('daluren') ? (baseprice * persons - 66) : (baseprice * persons - 71);
	}
	return price % 1 === 0 ? parseFloat(price) : parseFloat(price).toFixed(2);
}

export function getPath(fullPath) {
      const pathArray = fullPath.split('/')
      const poppedPath = pathArray.length > 1 ? pathArray.slice(0, -1) : ''
      const newPath = poppedPath.length > 1 ? poppedPath.join('/') : '/'+poppedPath[0]
      return newPath.endsWith('/') ? newPath : newPath+'/'
}

export function formatCurrency(value) {
	const decimals = Math.floor(value) === value ? 0 : 2
	const formatter = new Intl.NumberFormat('nl-NL', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: decimals
	})
	return formatter.format(value)
}