import { RECEIVE_SERVICES } from '../actions'

export const DEFAULT_SERVICE = 'defaultService'

let defaultService = [{
    type: '',
    id: '',
    serviceID: '',
    name: '',
    payments: {
        price: '',
        required: '',
    },
    maxTotalTicketsPerSlot: '',
}]

let defaultState = {
    [DEFAULT_SERVICE]: defaultService
}

export default function services(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_SERVICES:
            const { services } = action.payload
            return Object.assign({}, state, {
                services: services
            });

        default:
            return state
    }
}