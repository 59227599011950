import React,{ Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/nl'
import cn from 'classnames'
import { fetchServicesIfNeeded } from '../actions'
import {DEFAULT_SET} from '../reducers/setters'
import {DEFAULT_SERVICE} from '../reducers/services'
import { DEFAULT_POST } from '../reducers/posts'
import { DEFAULT_COUPON } from '../reducers/coupon'
import Coupon from './Coupon'
import {priceCalculator, formatCurrency} from './Helpers'
import { l } from './Language'

class SideBar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			expanded: false,
		}
		this.toggleBar = this.toggleBar.bind(this)
	}
	componentDidMount(){
		this.props.fetchServicesIfNeeded()
	}
	toggleBar() {
		this.setState({
			expanded: !this.state.expanded,
		})
	}
	render() {
		const { setRoomVal, setPlayersVal, setLanguageVal, setSlotVal, services, showCoupon, rooms, coupon, lang } = this.props
		const {expanded} = this.state
		const selectedService = services.find(o => o.id === setSlotVal.serviceID)
		const summaryClass = cn('sidebar-summary', {expanded: expanded})
		const discountedPrice = (setSlotVal.start !== '' && setPlayersVal > 0 && coupon.hasOwnProperty('promotion') && coupon.promotion.value !== '') && (coupon.promotion.type === 'direct' ? priceCalculator(selectedService, setPlayersVal) - parseFloat(coupon.promotion.value) : priceCalculator(selectedService, setPlayersVal) * (100 - parseFloat(coupon.promotion.value))/100)
		const isDiscounted = !isNaN(discountedPrice) && discountedPrice !== false && discountedPrice !== undefined
		return (
			<div className={summaryClass}>
				<h5 className="show-for-large">{l.sidebarSummary[lang]}</h5>
				<div className="the-sidebar">
					<div className="mobile-summary" onClick={this.toggleBar} style={{
							height: setPlayersVal || setSlotVal.start !== '' ? '4.5rem' : '3.5rem', 
							top: setPlayersVal || setSlotVal.start !== '' ? '-4.5rem' : '-3.5rem'}}>
						<div className="header">
							<h6>{l.summary[lang]}</h6>
							<div className="more">{expanded ? l.collapse[lang] : l.expand[lang]} <i className="material-icons">expand_less</i></div>
						</div>
						<div className="ordering" style={{display:'flex',alignItems:'center'}}>
							{(setSlotVal.start !== '' && setPlayersVal > 0) && 
							<div style={{fontSize:'1.5rem', fontWeight:'700',lineHeight:'1',marginRight:'1rem'}}>
								{ /*isDiscounted &&
									<span className="discounted">
									{formatCurrency(priceCalculator(selectedService, setPlayersVal))}
									</span>
								*/}
								{ isDiscounted ?
									formatCurrency(discountedPrice < 0 ? 0 : discountedPrice)
								:
									formatCurrency(priceCalculator(selectedService, setPlayersVal))
								}
							</div>
							}
							<div>
								<div style={{overflow: 'hidden', maxWidth: '75%', textOverflow: 'ellipsis'}}>
									{setRoomVal.title}
								</div>
								{setPlayersVal > 0 && setRoomVal.slug !== 'lockeddown' && <span className="players">{setPlayersVal}<i className="material-icons">person_outline</i></span>}
								{setSlotVal.start !== '' && <span>{`${moment(setSlotVal.start).locale(lang).tz('Europe/Amsterdam').format('D MMM Y')} ${l.at[lang]} ${moment(setSlotVal.start).tz('Europe/Amsterdam').format('HH:mm')}`}</span>}
							</div>
						</div>
					</div>
					<div className="sections"> 
						<Section className="room-selection">
							{setRoomVal.title}
						</Section>
						<Section>
							<Explanation 
								title={l.date[lang]}
								value={setSlotVal.start !== '' && `${moment(setSlotVal.start).tz('Europe/Amsterdam').format('D MMM Y')}`} />
							<Explanation 
								title={l.time[lang]} 
								value={setSlotVal.start !== '' && `
									${moment(setSlotVal.start).tz('Europe/Amsterdam').format('HH:mm')} - 
									${moment(setSlotVal.end).tz('Europe/Amsterdam').format('HH:mm')}`} />
							{ setRoomVal.slug !== 'lockeddown' && <Explanation title={l.players[lang]} value={setPlayersVal} /> }
							{/*<Explanation title={l.language[lang]} value={setLanguageVal && l[setLanguageVal][lang]} />*/}
						</Section>
						<Section>
							{ setRoomVal.slug !== 'lockeddown' && <Explanation 
								title={l.pricePerson[lang]}
								discountedValue={isDiscounted && formatCurrency(discountedPrice < 0 ? 0 : discountedPrice/setPlayersVal)}
								value={(setSlotVal.start !== '' && setPlayersVal > 0) && formatCurrency(priceCalculator(selectedService, setPlayersVal)/setPlayersVal) } />
							}
							<Explanation 
								title={l.total[lang]}
								discountedValue={isDiscounted && formatCurrency(discountedPrice < 0 ? 0 : discountedPrice)}
								value={(setSlotVal.start !== '' && setPlayersVal > 0) && formatCurrency(priceCalculator(selectedService, setPlayersVal)) } />
						</Section>
						<Section>
							{showCoupon ?
								<Coupon />
								:
								<div className="notice">{l.coupon[lang]}</div>
							}
						</Section>
						{!showCoupon && setRoomVal.slug !== 'lockeddown' && 
							<Section>
								<PriceTable services={services} currentRoom={setRoomVal || []} lang={lang}/>
							</Section>
						}
					</div>
				</div>
				<div className="last-minute">
					<i className="material-icons">phone</i> 
					<div>
						<span>{l.lastMinute1[lang]}</span>
						<span>{l.lastMinute2[lang]}</span>
					</div>
				</div>
		    </div>
	    )
	}
}

const Section = (props) => {
	const className = cn('section', props.className)
	return (
		<div className={className}>
			{props.children}
		</div>
	)
}

const Explanation = (props) => {
	return (
		<div className="explanation">
			<div className="left">{props.title}</div>
			<div className="right">
				{!props.discountedValue ?
					(props.value ? props.value : '-')
					:
					<span>
						<span className="discounted">
							{props.value}
						</span>
						<span className="newprice">
							{props.discountedValue}
						</span>
					</span>
				}
			</div>
		</div>
	)
}

const PriceTable = (props) => {
	const dalService = props.services.find(o => o.id === props.currentRoom.tb_dal_id && o.payments.price ) || {}
	const peakService = props.services.find(o => o.id === props.currentRoom.tb_id && o.payments.price ) || {}
	const persons = [3,4,5,6,7]
	return (
		<div className="price-table">
			<div className="tbl-cell first" style={{order: 1}}>
				
			</div>
			{persons.map( ( amount, i ) =>
				<div key={i} className="tbl-cell first" style={{order: i+2}}>
					{amount}
					<i className="material-icons">person_outline</i>
				</div>
			)}
			<div className="tbl-cell" style={{order: 1}}>
				<span className="btn dal">€ - {l.offPeak[props.lang]}</span>
			</div>
			{persons.map( ( amount, i ) =>
				<div key={i} className="tbl-cell" style={{order: i+2}}>
					{dalService.hasOwnProperty('payments') ? `€ ${priceCalculator(dalService, amount)}` : '-'}
				</div>
			)}
			<div className="tbl-cell" style={{order: 1}}>
				<span className="btn peak">€€ - {l.regular[props.lang]}</span>
			</div>
			{persons.map( ( amount, i ) =>
				<div key={i} className="tbl-cell" style={{order: i+2}}>
					{peakService.hasOwnProperty('payments') ? `€ ${priceCalculator(peakService, amount)}` : '-'}
				</div>
			)}
		</div>
	)
}

function mapStateToProps(state, ownProps) {
    const rooms = state.posts.escaperoom || state.posts[DEFAULT_POST]
    const setRoomVal = state.setters.setRoom || state.setters[DEFAULT_SET].setRoom
    const setPlayersVal = state.setters.setPlayers || state.setters[DEFAULT_SET].setPlayers
    const setLanguageVal = state.setters.setLanguage || state.setters[DEFAULT_SET].setLanguage
    const setSlotVal = state.setters.setSlot || state.setters[DEFAULT_SET].setSlot
    const calendarDate = state.setters.calendarDate || state.setters[DEFAULT_SET].setLanguage
    const services = state.services.services || state.services[DEFAULT_SERVICE]
    const coupon = state.coupon.coupon || state.coupon[DEFAULT_COUPON]
    const lang = state.setters.interfaceLanguage || state.setters[DEFAULT_SET].interfaceLanguage
    return {
        rooms: rooms,
        setRoomVal: setRoomVal,
        setPlayersVal: setPlayersVal,
        setLanguageVal: setLanguageVal,
        calendarDate: calendarDate,
        setSlotVal: setSlotVal,
        services: services,
        coupon: coupon,
        lang: lang,
    }
}

export default connect(mapStateToProps, {fetchServicesIfNeeded})(SideBar)