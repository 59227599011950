import { RECEIVE_PAGE } from '../actions'

export const DEFAULT_PAGE = 'defaultPage'

let defaultPage = {
    id: '',
    content: {
        rendered: ''
    },
    title: {
        rendered: ''
    },
    slug: '',
    featured_media: '',
    cmb2: {
        _save_intro_metabox: {
            _save_intro_content: '',
        },
        _save_page_metabox: {
            _save_page_subtitle_col_1: '',
            _save_page_content_col_1: '',
            _save_page_leesmeer_checkbox: '',
            _save_page_content_col_1_more: '',
            _save_page_subtitle_col_2: '',
            _save_page_content_col_2: '',
            _save_page_leesmeer_checkbox_2: '',
            _save_page_content_col_2_more: '',
            _save_page_subtitle_col_1_row_2: '',
            _save_page_content_col_1_row_2: '',
            _save_page_leesmeer_checkbox_3: '',
            _save_page_content_col_1_row_2_more: '',
            _save_page_title_secondary: '',
            _save_page_content_secondary: '',
        },
        _save_home_metabox: {
            _save_home_title: '',
            _save_home_text: '',
            _save_home_link: '',
        },
        _save_viervensters_metabox: {
            _save_viervensters_intro: '',
            _save_viervensters_group: [{
                title: '',
                text: '',
                image: '',
                image_id: '',
            }],
        },
    },
};

let defaultState = {
    [DEFAULT_PAGE]: defaultPage
};

export default function pages(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_PAGE:
            const { pageName, page } = action.payload

            return Object.assign({}, state, {
                [pageName]: page
            });

        default:
            return state
    }
}