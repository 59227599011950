import { RECEIVE_POSTS } from '../actions'

export const DEFAULT_POST = 'defaultPost'

let defaultPost = [{
    id: '',
    slug: '',
    title: '',
    type: '',
    featured_media: '',
    content: '',
    bullet1: '',
    bullet2: '',
    bullet3: '',
    bullet4: '',
    tb_id: '',
    tb_dal_id: '',
    special_enabled: '',
    special_text: '',
    special_green: '',
}]

let defaultState = {
    [DEFAULT_POST]: defaultPost
}

export default function posts(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_POSTS:
            const { posts, postType } = action.payload
            return Object.assign({}, state, {
                [postType]:posts
            });

        default:
            return state
    }
}