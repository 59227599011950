import { RECEIVE_COUPON } from '../actions'

export const DEFAULT_COUPON = 'defaultCoupon'

let defaultCoupon = {
    promotion: {
        name:'',
        coupon: '',
        value: '',
        type: '',
    }
}

let defaultState = {
    [DEFAULT_COUPON]: defaultCoupon
}

export default function coupon(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_COUPON:
            const { coupon } = action.payload
            return Object.assign({}, state, {
                coupon: coupon
            });

        default:
            return state
    }
}