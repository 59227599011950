import { RECEIVE_SLOTS } from '../actions'
import _ from 'lodash'

export const DEFAULT_SLOT = 'defaultSlot'

let defaultSlot = [{
    type: '',
    serviceID: '',
    start: '',
    end: '',
    tickets: '',
    isSoldout: '',
    gcalEvent: '',
    gcalID: '',
}]

let defaultState = {
    [DEFAULT_SLOT]: defaultSlot
}

export default function slots(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_SLOTS:
            const { room, slots } = action.payload
            const grouped = _.groupBy(slots, slot => slot.start.substr(0,10))
            return Object.assign({}, state, {
                [room]: grouped
            });

        default:
            return state
    }
}

// export default function slots(state = defaultState, action) {
//     switch(action.type) {
//         case RECEIVE_SLOTS:
//             const { room, slots } = action.payload
//             const grouped = _.groupBy(slots, slot => slot.start.substr(0,10))
//             return Object.assign({}, state, {
//                 [room]: Object.assign({}, state[room], {
//                     [start+end]: slots
//                 })
//             });

//         default:
//             return state
//     }
// }