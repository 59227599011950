import React,{ Component } from 'react'
import { connect } from 'react-redux'
import { fetchCoupon } from '../actions'
import {DEFAULT_SET} from '../reducers/setters'
import {DEFAULT_COUPON} from '../reducers/coupon'
import {DEFAULT_LOADING} from '../reducers/loading'
import {Loader} from './Loader'
import { l } from '../components/Language'

class Coupon extends Component {
	constructor(props) {
		super(props)
		this.state = {
			couponValue: '',
		}
		this.verifyCoupon = this.verifyCoupon.bind(this)
		this.onChange = this.onChange.bind(this)
	}
	verifyCoupon(service) {
		this.props.fetchCoupon(this.state.couponValue, this.props.setSlotVal.serviceID)
	}
	onChange(e) {
		this.setState({
			couponValue: e.target.value
		})
	}
	render() {
		const { setSlotVal, coupon, loadingCoupon, lang } = this.props
		return (
			<div>
				<div className="coupon">
					<Loader loading={loadingCoupon} />
					<input type="text" value={this.state.couponValue} onChange={this.onChange} disabled={setSlotVal === '' || loadingCoupon} placeholder={l.couponLabel[lang]}/>
					<button className="button small" onClick={this.verifyCoupon} disabled={setSlotVal === '' || loadingCoupon || this.state.couponValue===''} >{l.couponButton[lang]}</button>
				</div>
				{coupon === 'invalid' &&
					<div className="notice fail">{l.couponInvalid[lang]}</div>
				}
				{(coupon.hasOwnProperty('promotion') && coupon.promotion.value !== '') &&
					<div className="notice success">Code {coupon.promotion.name} {l.couponValid[lang]}</div>
				}
		    </div>
	    )
	}
}

function mapStateToProps(state, ownProps) {
    const setSlotVal = state.setters.setSlot || state.setters[DEFAULT_SET].setSlot
    const coupon = state.coupon.coupon || state.coupon[DEFAULT_COUPON]
    const lang = state.setters.interfaceLanguage || state.setters[DEFAULT_SET].interfaceLanguage
    const loadingCoupon = state.loading.coupon || state.loading[DEFAULT_LOADING].coupon
    return {
        setSlotVal: setSlotVal,
        coupon: coupon,
        loadingCoupon: loadingCoupon,
        lang: lang,
    }
}

export default connect(mapStateToProps, {fetchCoupon})(Coupon)