import React,{ Component } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'

import {DEFAULT_SET} from '../reducers/setters'
import {DEFAULT_POST} from '../reducers/posts'

import { setPlayers, setLanguage, setCalendarDate, setRoom } from '../actions'
import {NextButton} from '../components/Buttons'
import Calendar from '../components/Calendar'
import Sidebar from '../components/Sidebar'
import {getPath} from '../components/Helpers'
import { l } from '../components/Language'

class SelectDate extends Component {
	componentDidMount() {
    //get tb id from WP shortcode
    this.props.setRoom(window._DEFAULT_DATA)
	}
	render() {
		const { setPlayers, setLanguage, history, setRoomVal, setPlayersVal, setLanguageVal, setSlotVal, lang } = this.props
		return (
			<div className="grid-x grid-margin-x align-center">
				<div className="cell small-12 large-8 xlarge-7 xxlarge-6">
					<h5>{l.headerP2[lang]}</h5>
					<p>{l.introP2[lang]}</p>
					<div className="selector-wrap">
						<PlayerSelector onClick={setPlayers} active={setPlayersVal} lang={lang}/>
						{/*<LanguageSelector onClick={setLanguage} active={setLanguageVal} lang={lang}/>*/}
					</div>
					<Calendar room={setRoomVal.tb_id} roomDal={setRoomVal.tb_dal_id}/>
		    	</div>
				<div className="cell small-12 large-4 xxlarge-3 side">
					<Sidebar showCoupon={false}/>
					<NextButton text={l.continueP2[lang]} onClick={() => history.push(getPath(window.location.pathname)+'step2')} disabled={setPlayersVal === '' || setSlotVal.start === ''}/>
		    	</div>
		    </div>
	    )
	}
}

const PlayerSelector = (props) =>
	<div className="selector">
		<h6>{l.selectPlayers[props.lang]}</h6>
		<div className="select-boxes">
			<SelectBox text={3} onClick={props.onClick} value={3} active={props.active === 3}/>
			<SelectBox text={4} onClick={props.onClick} value={4} active={props.active === 4}/>
			<SelectBox text={5} onClick={props.onClick} value={5} active={props.active === 5}/>
			<SelectBox text={6} onClick={props.onClick} value={6} active={props.active === 6}/>
			<SelectBox text={7} onClick={props.onClick} value={7} active={props.active === 7}/>
		</div>
	</div>

const SelectBox = (props) => {
	const className = cn('select-box', {selected: props.active})
	return (
		<div className={className} onClick={props.onClick.bind(null, props.value)}>
			{props.text}
		</div>
	)
}

const LanguageSelector = (props) =>
	<div className="selector">
		<h6>{l.selectLanguage[props.lang]}</h6>
		<div className="select-boxes">
			<SelectBox text={l.dutch[props.lang]} onClick={props.onClick} value="dutch" active={props.active === 'dutch'}/>
			<SelectBox text={l.english[props.lang]} onClick={props.onClick} value="english" active={props.active === 'english'}/>
		</div>
	</div>


function mapStateToProps(state, ownProps) {
    const rooms = state.posts['escaperoom'] || state.posts[DEFAULT_POST]
    const setRoomVal = state.setters['setRoom'] || state.setters[DEFAULT_SET].setRoom
    const setPlayersVal = state.setters['setPlayers'] || state.setters[DEFAULT_SET].setPlayers
    const setLanguageVal = state.setters['setLanguage'] || state.setters[DEFAULT_SET].setLanguage
    const setSlotVal = state.setters['setSlot'] || state.setters[DEFAULT_SET].setSlot
    const calendarDate = state.setters['calendarDate'] || state.setters[DEFAULT_SET].setLanguage
    const lang = state.setters.interfaceLanguage || state.setters[DEFAULT_SET].interfaceLanguage
    return {
    	rooms: rooms,
      setRoomVal: setRoomVal,
      setPlayersVal: setPlayersVal,
      setLanguageVal: setLanguageVal,
      calendarDate: calendarDate,
      setSlotVal: setSlotVal,
      lang: lang,
    }
}

export default connect(mapStateToProps, {setPlayers, setLanguage, setCalendarDate, setRoom})(SelectDate)