import { RECEIVE_THUMB } from '../actions'

export const DEFAULT_THUMB = 'defaultThumb'

let defaultThumb = {
    id:'',
    media_details: {
        sizes: {
            medium: {
                source_url: '',
            },
            large: {
                source_url: '',
            },
            full: {
                source_url: '',
            }
        }
    },
    source_url: '',
}

let defaultState = {
    [DEFAULT_THUMB]: defaultThumb
}

export default function thumb(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_THUMB:
            const { thumb, thumbID } = action.payload
            return Object.assign({}, state, {
                [thumbID]: thumb
            });

        default:
            return state
    }
}