import React,{ Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment-timezone'
import _ from 'lodash'
import cn from 'classnames'
import {NextButton} from '../components/Buttons'
import {doReservation, setCustomer, resetReservation} from '../actions'
import Sidebar from '../components/Sidebar'
import { DEFAULT_SET } from '../reducers/setters'
import { DEFAULT_RATINGS } from '../reducers/ratings'
import { DEFAULT_RESERVATION } from '../reducers/reservation'
import { DEFAULT_LOADING } from '../reducers/loading'
import { DEFAULT_COUPON } from '../reducers/coupon'
import {validateEmail,getPath} from '../components/Helpers'
import {TransitionGroup} from 'react-transition-group'
import {SlideUp} from '../components/Transitions'
import { l } from '../components/Language'

import PaymentMethodsIMG from '../images/payment_methods.png';

class ContactDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			blurFirst: false,
			blurLast: false,
			blurEmail: false, 
			msgType: '',
			msgContent: '',
		}
		this.handleChange = this.handleChange.bind(this)
		this.processBooking = this.processBooking.bind(this)
		this.handleBlur = this.handleBlur.bind(this)
		this.setMsg = this.setMsg.bind(this)
		this.closeMsg = this.closeMsg.bind(this)
		this.setTimer = this.setTimer.bind(this)
		this.clearTimer = this.clearTimer.bind(this)
	}
	componentDidMount() {
		if ( this.props.setRoomVal.title === '' || this.props.setSlotVal.start === '' || this.props.setPlayersVal === '') {
			window.location.href = getPath(window.location.pathname)
		}
	}
	componentWillUnmount () {
		this.closeMsg()
		this.clearTimer()
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.reservation.code === 200) {
			if (nextProps.reservation.response.state === 'awaiting_payment') {
				window.location.href = nextProps.reservation.paymentURL.redirect
			} 
			else if (nextProps.reservation.response.state === 'confirmed'){
				this.setMsg('success', l.freeBooking[this.props.lang])
				this.setTimer()
			}
		}
		if (nextProps.reservation.code === 409 || nextProps.reservation.code === 400 ) {
			this.setMsg('error', nextProps.reservation.reason || nextProps.reservation.header)
			this.setTimer()
		}
	}
	handleChange(value, e) {
    	this.props.setCustomer(value, e.target.value)
    }
    handleBlur(tgt) {
    	this.setState({
    		[tgt]: true
    	})
    }
    setMsg(msgType = '', msg = '') {
    	this.setState({
    		msgType: msgType,
    		msgContent: msg,
    	})
    }
    closeMsg() {
    	this.setMsg()
    	this.props.resetReservation()
    }

    setTimer(msg) {
	    if (this.timerHandle) {
	      // Exception?
	      return;
	    }
	    // Remember the timer handle
	    this.timerHandle = setTimeout(() => {
	      this.closeMsg();
	      this.timerHandle = 0;
	    }, 8000);
	}
	clearTimer() {
	    // Is our timer running?
	    if (this.timerHandle) {
	        // Yes, clear it
	        clearTimeout(this.timerHandle);
	        this.timerHandle = 0;
	    }
	}

    processBooking() {
    	//create res object
    	const reservation = {
    		"type" : "reservation",
				"tickets" : this.props.setPlayersVal,
				"coworkerID":1,
				"service" : {
					"id" : this.props.setSlotVal.serviceID,
					"location" : ""
				},
				"datetime" : {
					"start" : moment(this.props.setSlotVal.start).utc().format('X'),
					"end" :  moment(this.props.setSlotVal.end).utc().format('X')
				},
				// "payment" : {
				// 	"gateway" : "mollie"
				// },
				"formFields" : [
					{"type":"form_field","name":"first_name","value":this.props.setCustomerVal.firstName},
					{"type":"form_field","name":"second_name","value":this.props.setCustomerVal.lastName},
					{"type":"form_field","name":"email","value":this.props.setCustomerVal.email},
					{"type":"form_field","name":"language","value":this.props.setLanguageVal},
					{"type":"form_field","name":"phone","value":this.props.setCustomerVal.phone},
				]
    	}
    	this.props.doReservation(this.props.setSlotVal, reservation, this.props.coupon)
    }
	render() {
		const { setCustomerVal, ratings, loadingReservationReq, lang } = this.props
		return (
			<div className="grid-x grid-margin-x align-center">
				<div className="cell small-12 large-8 xlarge-7 xxlarge-6">
					<h5>{l.headerP3[lang]}</h5>
					<p>{l.introP3[lang]}</p>
					<div className="form grid-x grid-margin-x">
						<Input type="text" name="firstName" required={true} label={l.firstName[lang]} onChange={this.handleChange} value={setCustomerVal.firstName} invalid={_.isEmpty(setCustomerVal.firstName) && this.state.blurFirst} onBlur={this.handleBlur.bind(this, 'blurFirst')}/>
						<Input type="text" name="lastName" required={true} label={l.lastName[lang]} onChange={this.handleChange} value={setCustomerVal.lastName} invalid={_.isEmpty(setCustomerVal.lastName) && this.state.blurLast} onBlur={this.handleBlur.bind(this, 'blurLast')}/>
						<Input type="email" name="email" required={true} label={l.email[lang]} onChange={this.handleChange} value={setCustomerVal.email} invalid={(_.isEmpty(setCustomerVal.email) || !validateEmail(setCustomerVal.email)) && this.state.blurEmail} onBlur={this.handleBlur.bind(this, 'blurEmail')} />
						<Input type="tel" name="phone" required={true} label={l.phone[lang]} onChange={this.handleChange} value={setCustomerVal.phone} onBlur={undefined}/>
					</div>
					{/*<RatingBar ratings={ratings} className="show-for-large"/>*/}
			    </div>
			    <div className="cell small-12 large-4 xxlarge-3 side">
					<Sidebar showCoupon={true} />
					<NextButton text={l.continueP3[lang]} onClick={this.processBooking} disabled={_.isEmpty(setCustomerVal.firstName) || _.isEmpty(setCustomerVal.lastName) || _.isEmpty(setCustomerVal.email) || !validateEmail(setCustomerVal.email) || loadingReservationReq}/>
		    		<div className="button-wrap">
						<div className="secure"><i className="material-icons">lock</i> {l.secureCheckout[lang]}</div>
						<img className="logos" src={PaymentMethodsIMG} alt="Payment methods"/>
					</div>
					{/*<RatingBar ratings={ratings} className="hide-for-large"/>*/}
		    	</div>
		    	<TransitionGroup>
			    	{this.state.msgType !== '' && 
			    		<SlideUp key={this.state.msgContent}>
				    		<NotifyMessage type={this.state.msgType} message={this.state.msgContent} onClick={this.closeMsg} lang={lang}/>
			    		</SlideUp>
			    	}
		    	</TransitionGroup>
			</div>
	    )
	}
}

const Input = (props) => {
	const theClass = cn(props.className, {invalid: props.invalid})
	return (
		<div className="cell small-12 medium-6 input">
			{props.label && <label>{props.label}</label>}
			<input type={props.type} name={props.name} value={props.value || ''} onChange={props.onChange.bind(this, props.name)} className={theClass} onBlur={props.onBlur}/>
		</div>
	)
}

const NotifyMessage = (props) => {
	const className = cn('error-message', {success: props.type === 'success'})
	return (
		<div className={className}>
			<div className="close" onClick={props.onClick}>
				<i className="material-icons">close</i>
			</div>
			<div className="message">
				{props.type === 'error' && <h4>{l.errorHeader[props.lang]}</h4>}
				<p>{props.message}</p>
				{props.type === 'error' && <p>{l.errorMessage[props.lang]}</p>}
			</div>
		</div>
	)
}

const RatingBar = (props) => {
	const className=cn('reviews-hero',props.className)
	return (
		<div className={className}>
			<div className="wrapper">
				<div className="review icon-wrap">
					<img src={`${process.env.REACT_APP_TEMPLATE_URL}/assets/images/facebook.svg`} alt="Facebook reviews"/>
					<div className="content">
						<div className="rating">
							<div className="rating-holder">
								<div className="c-rating c-rating--big" data-rating-value={props.ratings.Facebook.rating}>
									<button>1</button>
									<button>2</button>
									<button>3</button>
									<button>4</button>
									<button>5</button>
								</div>
							</div>
						</div>
						<div className="number">{props.ratings.Facebook.total} reviews</div>
					</div>
				</div>
			</div>
			<div className="wrapper">
				<div className="review icon-wrap">
					<img src={`${process.env.REACT_APP_TEMPLATE_URL}/assets/images/tripadvisor.svg`}  alt="Tripadvisor reviews"/>
					<div className="content">
						<div className="rating">
							<div className="rating-holder">
								<div className="c-rating c-rating--big" data-rating-value={props.ratings.Tripadvisor.rating}>
									<button>1</button>
									<button>2</button>
									<button>3</button>
									<button>4</button>
									<button>5</button>
								</div>
							</div>
						</div>
						<div className="number">{props.ratings.Tripadvisor.total} reviews</div>
					</div>
				</div>
			</div>
			<div className="wrapper">
				<div className="review icon-wrap">
					<img src={`${process.env.REACT_APP_TEMPLATE_URL}/assets/images/google.svg`}  alt="Google reviews"/>
					<div className="content">
						<div className="rating">
							<div className="rating-holder">
								<div className="c-rating c-rating--big" data-rating-value={props.ratings.Google.rating}>
									<button>1</button>
									<button>2</button>
									<button>3</button>
									<button>4</button>
									<button>5</button>
								</div>
							</div>
						</div>
						<div className="number">{props.ratings.Google.total} reviews</div>
					</div>
				</div>
			</div>
			<div className="wrapper">
				<div className="review icon-wrap">
					<img src={`${process.env.REACT_APP_TEMPLATE_URL}/assets/images/escaperoomsnl.svg`}  alt="Escaperooms Nederland reviews"/>
					<div className="content">
						<div className="rating">
							<div className="rating-holder">
								<div className="c-rating c-rating--big" data-rating-value={props.ratings.EscaperoomsNL.rating}>
									<button>1</button>
									<button>2</button>
									<button>3</button>
									<button>4</button>
									<button>5</button>
								</div>
							</div>
						</div>
						<div className="number">{props.ratings.EscaperoomsNL.total} reviews</div>
					</div>
				</div>
			</div>
		</div>
	)
}

function mapStateToProps(state, ownProps) {
    const setRoomVal = state.setters.setRoom || state.setters[DEFAULT_SET].setRoom
    const setSlotVal = state.setters.setSlot || state.setters[DEFAULT_SET].setSlot
    const setPlayersVal = state.setters.setPlayers || state.setters[DEFAULT_SET].setPlayers
    const setCustomerVal = state.setters.setCustomer || state.setters[DEFAULT_SET].setCustomer
    const lang = state.setters.interfaceLanguage || state.setters[DEFAULT_SET].interfaceLanguage
    const ratings = state.ratings.ratings || state.ratings[DEFAULT_RATINGS]
    const reservation = state.reservation.reservation || state.reservation[DEFAULT_RESERVATION]
    const loadingReservationReq = state.loading.reservation || state.loading[DEFAULT_LOADING].reservation
    const coupon = state.coupon.coupon || state.coupon[DEFAULT_COUPON]
    return {
        setRoomVal: setRoomVal,
        setSlotVal: setSlotVal,    
        setPlayersVal: setPlayersVal,
        setLanguageVal: lang,
        setCustomerVal: setCustomerVal,
        ratings: ratings,
        reservation: reservation,
        loadingReservationReq: loadingReservationReq,
        lang: lang,
        coupon: coupon,
    }
}

export default connect(mapStateToProps, {doReservation, setCustomer, resetReservation})(ContactDetails)