import { SET_ROOM, SET_SLOT, SET_CUSTOMER, SET_PLAYERS, SET_LANGUAGE, SET_CALENDAR_DATE, SET_INTERFACE_LANGUAGE } from '../actions'
import moment from 'moment'

export const DEFAULT_SET = 'defaultSet'

let defaultSet = {
    setRoom: {
        slug: '',
        title: '',
    },
    setSlot: {
        start: '',
    },
    setCustomer: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
    },
    setPlayers: '',
    setLanguage: '',
    calendarDate: moment().startOf('isoWeek'),
    interfaceLanguage: '',
};

let defaultState = {
    [DEFAULT_SET]: defaultSet
};

export default function setters(state = defaultState, action) {
    switch(action.type) {
        case SET_ROOM:
            const { setRoom } = action.payload
            return Object.assign({}, state, {
                setRoom: setRoom
            });

        case SET_SLOT:
            const { setSlot } = action.payload
            return Object.assign({}, state, {
                setSlot: setSlot
            });

        case SET_PLAYERS:
            const { setPlayers } = action.payload
            return Object.assign({}, state, {
                setPlayers: setPlayers
            });

        case SET_LANGUAGE:
            const { setLanguage } = action.payload
            return Object.assign({}, state, {
                setLanguage: setLanguage
            });

        case SET_CUSTOMER:
            const { property, value } = action.payload

            return Object.assign({}, state, {
                setCustomer: Object.assign({}, state.setCustomer, {
                    [property]: value
                })
            });

        case SET_CALENDAR_DATE:
            const { calendarDate } = action.payload
            return Object.assign({}, state, {
                calendarDate: calendarDate
            });

        case SET_INTERFACE_LANGUAGE:
            const { interfaceLanguage } = action.payload
            return Object.assign({}, state, {
                interfaceLanguage: interfaceLanguage
            });


        default:
            return state
    }
}