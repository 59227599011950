import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import pages from './pages'
import posts from './posts'
import thumb from './thumb'
import setters from './setters'
import slots from './slots'
import services from './services'
import coupon from './coupon'
import loading from './loading'
import ratings from './ratings'
import reservation from './reservation'

export default combineReducers({
  router: routerReducer,
  pages,
  posts,
  thumb,
  setters,
  slots,
  services,
  coupon,
  loading,
  ratings,
  reservation,
})