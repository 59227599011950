import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import ReactCSSTransitionReplace from 'react-css-transition-replace'

import ScrollToTop from './components/ScrollToTop'

import SelectDate from './sections/SelectDate'
import ContactDetails from './sections/ContactDetails'
import Progress from './components/Progress'
import {getPath } from './components/Helpers'

/*
get current lang todo
*/

const windowPath = getPath(window.location.pathname)

class App extends Component {
  render() {
    return (
      <Route render={({location}) => (
        <div>
          <Route component={Progress} />
          <ReactCSSTransitionReplace transitionName="fade" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
            <ScrollToTop key={location.pathname}>
              <Switch location={location}>
                <Route exact path={`${windowPath}`} component={SelectDate}/>
                <Route exact path={`${windowPath}step2`} component={ContactDetails}/>
              </Switch>
            </ScrollToTop>
          </ReactCSSTransitionReplace>
        </div>
      )}/>
    );
  }
}


export default App