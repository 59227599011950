import fetch from 'isomorphic-fetch'
import _ from 'lodash'
import qs from 'query-string'

export const RECEIVE_PAGE = 'RECEIVE_PAGE'
export const RECEIVE_POSTS = 'RECEIVE_POSTS'
export const RECEIVE_THUMB = 'RECEIVE_THUMB'
export const RECEIVE_SLOTS = 'RECEIVE_SLOTS'
export const RECEIVE_SERVICES = 'RECEIVE_SERVICES'
export const RECEIVE_RESERVATION = 'RECEIVE_RESERVATION'
export const RECEIVE_COUPON = 'RECEIVE_COUPON'

export const LOADING_PAGES = 'LOADING_PAGES'
export const LOADING_POSTS = 'LOADING_POSTS'
export const LOADING_SERVICES = 'LOADING_SERVICES'
export const LOADING_SLOTS = 'LOADING_SLOTS'
export const LOADING_COUPON = 'LOADING_COUPON'
export const LOADING_RESERVATION_REQUEST = 'LOADING_RESERVATION_REQUEST'

export const SET_ROOM = 'SET_ROOM'
export const SET_SLOT = 'SET_SLOT'
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SET_PLAYERS = 'SET_PLAYERS'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_CALENDAR_DATE = 'SET_CALENDAR_DATE'
export const RECEIVE_RATINGS = 'RECEIVE_RATINGS'
export const SET_INTERFACE_LANGUAGE = 'SET_INTERFACE_LANGUAGE'

export const RESET_RESERVATION = 'RESET_RESERVATION'

function loadingPages(bool) {
    return {
        type: LOADING_PAGES,
        payload: {
            loading: bool
        }
    }
}
function loadingPosts(bool) {
    return {
        type: LOADING_POSTS,
        payload: {
            loading: bool
        }
    }
}
function loadingServices(bool) {
    return {
        type: LOADING_SERVICES,
        payload: {
            loading: bool
        }
    }
}
function loadingSlots(bool) {
    return {
        type: LOADING_SLOTS,
        payload: {
            loading: bool
        }
    }
}
function loadingCoupon(bool) {
    return {
        type: LOADING_COUPON,
        payload: {
            loading: bool
        }
    }
}
function loadingReservationRequest(bool) {
    return {
        type: LOADING_RESERVATION_REQUEST,
        payload: {
            loading: bool
        }
    }
}

export function fetchPageIfNeeded(pageName) {
    return function(dispatch, getState) {
        if (shouldFetchPage(getState(), pageName)) {
            dispatch(loadingPages(true))
            return fetch(process.env.REACT_APP_WP_JSON_URL + '/wp/v2/pages?slug=' + pageName, {credentials: 'same-origin'})
                .then(response => response.json())
                .then(pages => dispatch(receivePage(dispatch, pageName, pages[0])))
        }
    }
}

function shouldFetchPage(state, pageName) {
    const pages = state.pages
    return !pages.hasOwnProperty(pageName)
}

function receivePage(dispatch, pageName, pageData) {
    dispatch(loadingPages(false))
    return {
        type: RECEIVE_PAGE,
        payload: {
            pageName: pageName,
            page: pageData
        }
    }
}

export function fetchPostsIfNeeded(postType, language) {
    return function(dispatch, getState) {
        if (shouldFetchPosts(getState(), postType)) {
            dispatch(loadingPosts(true))
            return fetch(process.env.REACT_APP_WP_JSON_URL + '/custom/v1/' + postType + '?lang=' +language, {credentials: 'same-origin'})
                .then(response => response.json())
                .then(posts => dispatch(receivePosts(dispatch, postType, posts)))
        }
    }
}

function shouldFetchPosts(state, postType) {
    const posts = state.posts;
    return !posts.hasOwnProperty(postType);
}

function receivePosts(dispatch, postType, posts) {
    dispatch(loadingPosts(false))
    const sortedPosts = _.sortBy( posts, 'menu_order' )
    return {
        type: RECEIVE_POSTS,
        payload: {
            postType: postType,
            posts: sortedPosts
        }
    }
}

export function fetchThumbIfNeeded(thumbID) {
    return function(dispatch, getState) {
        if (shouldFetchThumb(getState(), thumbID)) {
            return fetch(process.env.REACT_APP_WP_JSON_URL + '/wp/v2/media/' + thumbID, {credentials: 'same-origin'})
                .then(response => response.json())
                .then(thumb => dispatch(receiveThumb(dispatch, thumbID, thumb)) )
        }
    }
}

function shouldFetchThumb(state, thumbID) {
    const thumb = state.thumb;
    return !thumb.hasOwnProperty(thumbID);
}

function receiveThumb(dispatch, thumbID, thumb) {
    return {
        type: RECEIVE_THUMB,
        payload: {
            thumbID: thumbID,
            thumb: thumb
        }
    }
}

//setters
export function setRoom(room) {
	return {
        type: SET_ROOM,
        payload: {
            setRoom: room
        }
    }
}

export function setSlot(slot) {
	return {
        type: SET_SLOT,
        payload: {
            setSlot: slot
        }
    }
}

export function setPlayers(amount) {
	return {
        type: SET_PLAYERS,
        payload: {
            setPlayers: amount
        }
    }
}

export function setLanguage(lang) {
	return {
        type: SET_LANGUAGE,
        payload: {
            setLanguage: lang
        }
    }
}

export function setCustomer(property, value) {
	return {
        type: SET_CUSTOMER,
        payload: {
            property: property,
            value: value
        }
    }
}

export function setCalendarDate(date) {
    return {
        type: SET_CALENDAR_DATE,
        payload: {
            calendarDate: date
        }
    }
}

export function setInterfaceLanguage(lang) {
    return {
        type: SET_INTERFACE_LANGUAGE,
        payload: {
            interfaceLanguage: lang
        }
    }
}

//teambooker getters and setters
// export function fetchSlotsIfNeeded(room, start, end) {
//     return function(dispatch, getState) {
//         if (shouldFetchSlots(getState(), room, start, end)) {
//             dispatch(loadingSlots(true))
//             return fetch(process.env.REACT_APP_WP_ADMIN_AJAX + '?action=teambooking_rest_api&operation=get_day_slots&service_id=' + room + '&start='+start+'&end='+end+'&auth_token=' + process.env.REACT_APP_TB_API_KEY)
//                 .then(response => response.json())
//                 .then(slots => dispatch(receiveSlots(dispatch, room, start, end, slots.response)))
//         }
//     }
// }

// function shouldFetchSlots(state, room, start, end) {
//     const slots = state.slots;
//     return !slots.hasOwnProperty(start+end);
// }

// function receiveSlots(dispatch, room, start, end, slots) {
//     dispatch(loadingSlots(false))
//     return {
//         type: RECEIVE_SLOTS,
//         payload: {
//             room: room,
//             slots: slots,
//             start: start,
//             end: end,
//         }
//     }
// }

export function fetchSlotsIfNeeded(room) {
    return function(dispatch, getState) {
        if (shouldFetchSlots(getState(), room)) {
            dispatch(loadingSlots(true))
            return fetch(process.env.REACT_APP_WP_ADMIN_AJAX + '?action=teambooking_rest_api&operation=get_slots&service_id=' + room + '&auth_token=' + process.env.REACT_APP_TB_API_KEY)
                .then(response => response.json())
                .then(slots => dispatch(receiveSlots(dispatch, room, slots.response)))
        }
    }
}

function shouldFetchSlots(state, room) {
    const slots = state.slots;
    return !slots.hasOwnProperty(room);
}

function receiveSlots(dispatch, room, slots) {
    dispatch(loadingSlots(false))
    return {
        type: RECEIVE_SLOTS,
        payload: {
            room: room,
            slots: slots,
        }
    }
}

export function doReservation(slot, customer, coupon) {
    return function(dispatch, getState) {
        dispatch(loadingReservationRequest(true))
        return fetch(process.env.REACT_APP_WP_ADMIN_AJAX,
        	{
        		method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
        		body: qs.stringify({
        			action: "teambooking_rest_api",
        			operation: "do_reservation",
        			auth_token: process.env.REACT_APP_TB_API_KEY_RW,
        			reservation: JSON.stringify(customer),
        			slot: JSON.stringify(slot),
                    promotion_code: coupon.hasOwnProperty('promotion') ? coupon.promotion.coupon : '',
        		})
        	})
            .then(response => response.json())
            .then(info => dispatch(receiveReservation(dispatch, info)))
            .catch(error => dispatch(receiveReservation(dispatch, {
                code: 400,
                reason: 'Could not connect to reservation service.',
            })) )
    }
}

function receiveReservation(dispatch, info) {
    dispatch(loadingReservationRequest(false))
    return {
        type: RECEIVE_RESERVATION,
        payload: {
            info:info
        }
    }
}

export function resetReservation() {
    return {
        type: RESET_RESERVATION,
    }
}

export function fetchServicesIfNeeded() {
    return function(dispatch, getState) {
        if (shouldFetchServices(getState())) {
            dispatch(loadingServices(true))
            return fetch(process.env.REACT_APP_WP_ADMIN_AJAX + '?action=teambooking_rest_api&operation=get_services&auth_token=' + process.env.REACT_APP_TB_API_KEY)
                .then(response => response.json())
                .then(res => dispatch(receiveServices(dispatch, res.response)))
        }
    }
}

function shouldFetchServices(state) {
    const services = state.services;
    return !services.hasOwnProperty('services');
}

function receiveServices(dispatch, services) {
    dispatch(loadingServices(false))
    return {
        type: RECEIVE_SERVICES,
        payload: {
            services: services
        }
    }
}

export function fetchCoupon(coupon, service) {
    return function(dispatch, getState) {
        dispatch(loadingCoupon(true))
        return fetch(process.env.REACT_APP_WP_ADMIN_AJAX + '?action=teambooking_rest_api&operation=get_promotion&service_id='+service+'&promotion_code='+coupon+'&auth_token=' + process.env.REACT_APP_TB_API_KEY)
            .then(response => response.json())
            .then(res => dispatch(receiveCoupon(dispatch, res.response)))
    }
}

function receiveCoupon(dispatch, coupon) {
    dispatch(loadingCoupon(false))
    return {
        type: RECEIVE_COUPON,
        payload: {
            coupon: coupon
        }
    }
}

export function getRatings() {
    return function(dispatch, getState) {
        return fetch(process.env.REACT_APP_TEMPLATE_URL+'/plugins/scraper/results.json')
            .then(response => response.json())
            .then(res => dispatch(receiveRatings(dispatch, res)))
    }
}

function receiveRatings(dispatch, ratings) {
    return {
        type: RECEIVE_RATINGS,
        payload: {
            ratings: ratings
        }
    }
}