import { LOADING_PAGES, LOADING_POSTS, LOADING_SERVICES, LOADING_SLOTS, LOADING_COUPON, LOADING_RESERVATION_REQUEST } from '../actions'

export const DEFAULT_LOADING = 'defaultLoading'

let defaultLoading = {
    pages: false,
    posts: false,
    services: false,
    slots: false,
    coupon: false,
    reservation: false,
}

let defaultState = {
    [DEFAULT_LOADING]: defaultLoading
}

export default function loading(state = defaultState, action) {
    switch(action.type) {
        case LOADING_PAGES:
            return Object.assign({}, state, {
                pages: action.payload.loading
            });
        case LOADING_POSTS:
            return Object.assign({}, state, {
                posts: action.payload.loading
            });
        case LOADING_SERVICES:
            return Object.assign({}, state, {
                services: action.payload.loading
            });
        case LOADING_SLOTS:
            return Object.assign({}, state, {
                slots: action.payload.loading
            });
        case LOADING_COUPON:
            return Object.assign({}, state, {
                coupon: action.payload.loading
            });

        case LOADING_RESERVATION_REQUEST:
            return Object.assign({}, state, {
                reservation: action.payload.loading
            });

        default:
            return state
    }
}