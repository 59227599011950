import { RECEIVE_RESERVATION, RESET_RESERVATION } from '../actions'

export const DEFAULT_RESERVATION = 'defaultReservation'

let defaultReservation = {
    code: '',
    response: {
        state: '',
    },
}

let defaultState = {
    [DEFAULT_RESERVATION]: defaultReservation
}

export default function reservation(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_RESERVATION:
            const { info } = action.payload
            return Object.assign({}, state, {
                reservation: info
            });

        case RESET_RESERVATION: 
            return Object.assign({}, state, {
                reservation: defaultReservation
            });
            
        default:
            return state
    }
}