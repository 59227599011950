import React,{ Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/nl'
import cn from 'classnames'
import _ from 'lodash'

import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';

import { fetchSlotsIfNeeded, setSlot, setCalendarDate } from '../actions'
import {DEFAULT_SLOT} from '../reducers/slots'
import {DEFAULT_SET} from '../reducers/setters'
import {DEFAULT_LOADING} from '../reducers/loading'
import {Loader} from './Loader'
import { l } from '../components/Language'

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

class Calendar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			index:0,
			theWeek: this.setWeek(props.calendarDate),
		}
		this.slideRenderer = this.slideRenderer.bind(this)
		this.changeWeek = this.changeWeek.bind(this)
		this.getWeek = this.getWeek.bind(this)
		this.fetchSlots = this.fetchSlots.bind(this)
	}
	componentDidMount(){
		!_.isEmpty(this.props.room) && this.fetchSlots(this.props.room)
		!_.isEmpty(this.props.roomDal) && this.fetchSlots(this.props.roomDal)
	}
	componentWillReceiveProps(nextProps){
		this.props.room !== nextProps.room && this.fetchSlots(nextProps.room)
		this.props.roomDal !== nextProps.roomDal && this.fetchSlots(nextProps.roomDal)	
	}
	fetchSlots(room) {
		this.props.fetchSlotsIfNeeded(room)
	}
	setWeek(date) {
		let days = [];
		let day = date;
		let end = moment(date).endOf('isoWeek')
		while (day <= end) {
		    days.push(day.toDate());
		    day = day.clone().add(1, 'd');
		}
		return days
	}
	changeWeek(amount, unit) {
		this.getWeek(this.state.index + amount)
	}

	slideRenderer({key, index}) {
		const theWeek = this.setWeek(moment(this.props.calendarDate.clone().add(key, 'week')))
		return (
			<CalendarTable 
				key={key}
				week={theWeek} 
				slots={this.props.slots} 
				slotsDal={this.props.slotsDal} 
				setSlotVal={this.props.setSlotVal} 
				setSlot={this.props.setSlot}
				lang={this.props.lang} />
		)
	}

	getWeek(index, indexLatest, meta) {
		const theWeek = this.setWeek(moment(this.props.calendarDate.clone().add(index, 'week')))
		this.setState({
			index: index,
			theWeek: theWeek
		})
	}

	render() {
		const { slots, slotsDal, setSlotVal, loadingSlots, lang } = this.props
		const { theWeek } = this.state
		return (
			<div className="calendar-wrap">
				<div className="calendar-header">
					<WeekSwitch changeWeek={this.changeWeek} icon="navigate_before" label={l.prev[lang]} direction={-1} disabled={this.state.index === 0 || loadingSlots}/>
					<div className="current-week">
						{moment(theWeek[0]).locale(lang).tz('Europe/Amsterdam').format('MMM D')} - {moment(theWeek[theWeek.length-1]).locale(lang).tz('Europe/Amsterdam').format('MMM D')}
					</div>
					<WeekSwitch changeWeek={this.changeWeek} icon="navigate_next" label={l.next[lang]} direction={1} disabled={loadingSlots} />
				</div>
				<Loader loading={loadingSlots}/>
				<VirtualizeSwipeableViews 
					slideRenderer={this.slideRenderer}
					index={this.state.index}
					enableMouseEvents={false}
					slideCount={100}
					overscanSlideBefore={1}
					overscanSlideAfter={2}
					onChangeIndex={this.getWeek}
					extra={setSlotVal.start}
					slots={slots.length}
					slotsdal={slotsDal.length} />
			</div>
		)
	}
}

const WeekSwitch = (props) => {
	const className = cn('nav', {disabled: props.disabled})
	return (
		<div className={className} onClick={props.disabled ? undefined : props.changeWeek.bind(null, props.direction, 'week')}>
			{props.direction > 0 && props.label} <i className="material-icons">{props.icon}</i> {props.direction < 0 && props.label}
		</div>
	)
}

const CalendarTable = (props) =>
	<div className="calendar-table">
		{props.week.map( (day, i) => {
			const curDay = moment(day).tz('Europe/Amsterdam').startOf('day').format('YYYY-MM-DD')
			return (
				<div className="day" key={i}>
					<div className="tbl-cell date">
				 		{moment(day).locale(props.lang).tz('Europe/Amsterdam').format('dd D')}
				 	</div>
					<div className="slot-wrap">
					 	{props.slotsDal.hasOwnProperty(curDay) && props.slotsDal[curDay].map( (slot,j) => 
					 		<Slot lang={props.lang} key={j} active={props.setSlotVal.start === slot.start} slot={slot} setSlot={props.setSlot} dal={slot.serviceID.indexOf('dal') !== -1}/>
						)}
					 	{props.slots.hasOwnProperty(curDay) && props.slots[curDay].map( (slot,j) => 
					 		<Slot lang={props.lang} key={j} active={props.setSlotVal.start === slot.start} slot={slot} setSlot={props.setSlot} dal={slot.serviceID.indexOf('dal') !== -1} />
						)}
					</div>
				</div>
			)})
		}
	</div>

function timeStringToFloat(time) {
  var hoursMinutes = time.split(/[.:]/);
  var hours = parseInt(hoursMinutes[0], 10);
  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return hours + minutes / 60;
}

const Slot = (props) => {
	const soldOut = props.slot.isSoldout
	const className = cn('tbl-cell slot', {selected: props.active, soldout: soldOut, dal: props.dal})
	const startTime = moment(props.slot.start).tz('Europe/Amsterdam').format('HH:mm')
	const endTime = moment(props.slot.end).tz('Europe/Amsterdam').format('HH:mm')
	// const slotDiff = timeStringToFloat((parseFloat(moment(props.slot.start).tz('Europe/Amsterdam').format('H.mm')) - 10).toFixed(2))/2
	const slotDiff = parseFloat(timeStringToFloat(moment(props.slot.start).tz('Europe/Amsterdam').format('H.mm')) - 10)/2
	return (
		<div className={className} onClick={!soldOut ? props.setSlot.bind(this, props.slot) : undefined} style={{top:slotDiff * 3 + 'rem'}}>
			<div className="time">
				<span className="start">{startTime}</span>
				<span className="sep">-</span>
				<span className="end">{endTime}</span>
			</div>
			<div className="available">
				{soldOut ? l.slotSoldout[props.lang] : l.slotAvailable[props.lang]}
			</div>
		</div>
	)
}

function mapStateToProps(state, ownProps) {
    const setRoomVal = state.setters.setRoomVal || state.setters[DEFAULT_SET].setRoomVal
    const slotsDal = state.slots[ownProps.roomDal] || state.slots[DEFAULT_SLOT]
    const slots = state.slots[ownProps.room] || state.slots[DEFAULT_SLOT]
    const calendarDate = state.setters.calendarDate || state.setters[DEFAULT_SET].calendarDate
    const setSlotVal = state.setters.setSlot || state.setters[DEFAULT_SET].setSlot
    const loadingSlots = state.loading.slots || state.loading[DEFAULT_LOADING].slots
    const lang = state.setters.interfaceLanguage || state.setters[DEFAULT_SET].interfaceLanguage
    return {
    	slotsDal: slotsDal,
    	slots: slots,
        calendarDate: calendarDate,
        setSlotVal: setSlotVal,
        loadingSlots: loadingSlots,
        setRoomVal: setRoomVal,
        lang: lang,
    }
}

export default connect(mapStateToProps, {fetchSlotsIfNeeded, setSlot, setCalendarDate})(Calendar)