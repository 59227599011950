import React from 'react'
import {CSSTransition} from 'react-transition-group'

export const Fade = ({ children, ...props }) => (
	<CSSTransition {...props} timeout={600} classNames="fade">
		{children}
	</CSSTransition>
)

export const FadeAbsolute = ({ children, ...props }) => (
	<CSSTransition {...props} timeout={600} classNames="absolute fade">
		{children}
	</CSSTransition>
)

export const SlideUp = ({ children, ...props }) => (
	<CSSTransition {...props} timeout={400}	classNames="slide-up">
		{children}
	</CSSTransition>
)