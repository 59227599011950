import React,{ Component } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import {Link} from 'react-router-dom'

import { setInterfaceLanguage } from '../actions'
import { DEFAULT_POST } from '../reducers/posts'
import { DEFAULT_SET } from '../reducers/setters'
import {getPath } from './Helpers'
import { l } from '../components/Language'


class Progress extends Component {
	componentDidMount() {
    	window.location.pathname.indexOf('/en/') === -1 ? this.props.setInterfaceLanguage('nl') : this.props.setInterfaceLanguage('en')
	}
	render() {
		const currentPath = this.props.location.pathname
		const { setRoomVal, setPlayersVal, setSlotVal, setLanguageVal, lang } = this.props
		const currentStep = currentPath.indexOf('step2') !== -1 ? 2 : 1
		const windowPath = getPath(window.location.pathname)
		return (
			<div className="progress-wrap row align-center">
				<div className="columns small-12 xlarge-10 xxlarge-8">
					<div className="line">
						<div className="progress" style={{width:`${(currentStep-1) * 100/2}%`}}/>
						<div className="cirkles">
							<Circle active={currentStep > 0 }/>
							<Circle active={currentStep > 1 }/>
							<Circle />
						</div>
					</div>
					<div className="progress-bar">
						<Step title={l.progress2[lang]} active={currentStep === 1} to={`${windowPath}`} available={true} />
						<Step title={l.progress3[lang]} active={currentStep === 2} to={`${windowPath}step2`} available={setPlayersVal !== '' && setSlotVal !== '' && setLanguageVal !== ''}/>
						<Step title={l.progress4[lang]} available={false}/>
					</div>
				</div>
		    </div>
	    )
	}
}

const Step = (props) => {
	const step = cn('step', {active: props.active, disabled: !props.available})
	return (
		props.available ?
		<Link className={step} to={props.to}>
			{props.title}
		</Link>
		:
		<a className={step}>
			{props.title}
		</a>
	)
}

const Circle = (props) => {
	const circle = cn('circle', {active: props.active})
	return (
		<div className={circle}/>
	)
}

function mapStateToProps(state, ownProps) {
    const rooms = state.posts['escaperoom'] || state.posts[DEFAULT_POST]
    const setRoomVal = state.setters['setRoom'] || state.setters[DEFAULT_SET].setRoom
    const setSlotVal = state.setters['setSlot'] || state.setters[DEFAULT_SET].setSlot
    const setPlayersVal = state.setters['setPlayers'] || state.setters[DEFAULT_SET].setPlayers
    const setLanguageVal = state.setters['setLanguage'] || state.setters[DEFAULT_SET].setLanguage
    const lang = state.setters.interfaceLanguage || state.setters[DEFAULT_SET].interfaceLanguage
    return {
        rooms: rooms,
        setRoomVal: setRoomVal,
        setSlotVal: setSlotVal,    
        setPlayersVal: setPlayersVal,
        setLanguageVal: setLanguageVal,
        lang: lang,
    }
}

export default connect(mapStateToProps,{setInterfaceLanguage})(Progress)