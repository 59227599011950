import React from 'react'
import { TransitionGroup } from 'react-transition-group'
import {Spinner} from '../images/Spinner'
import { FadeAbsolute } from './Transitions'

export const Loader = (props) => {
	const { loading } = props
    return (
        <TransitionGroup>
            {loading && 
                <FadeAbsolute>
                    <div className="loading">
                        <Spinner/>
                    </div>
                </FadeAbsolute>
            }
        </TransitionGroup>
	)
}