export const l = {
	introP1: {
		en: "Select a room to get started with the booking process and follow the steps on the next pages.",
		nl: "Selecteer een spel om het boekingsproces te beginnen en doorloop de stappen op de volgende pagina's.",
	},
	introP2: {
		en: "The prices at Escapist are dependant on the amount of players, and the time you choose. See the table in the order summary for more details.",
		nl: "De prijzen bij Escapist zijn afhankelijk van het aantal spelers en het gekozen tijdstip. Zie de tabel in het order overzicht voor meer details.",
	},
	introP2VR: {
		en: "Play with as many people as you like (though we recommend no more than 6, to prevent too much chaos). For our VR experience we charge a fixed sum of € 125.",
		nl: "Speel met zoveel mensen als je wilt (hoewel, met meer dan 6 wordt waarschijnlijk chaos). Voor onze VR experience rekenen we een vaste prijs van € 125.",
	},
	introP3: {
		en: "Enter your details and confirm your booking. You'll be forwarded to our secure payment service provider. After your payment has been confirmed, your spot is reserved.",
		nl: "Vul je gegevens in en bevesting je boeking. Je wordt vervolgens doorgeleid naar onze beveiligde betaalomgeving. Na je betaling is je plek gereserveerd.",
	},
	continueP1: {
		en: "Continue to date & time",
		nl: "Ga verder naar datum & tijd",
	},
	continueP2: {
		en: "Continue to contact details",
		nl: "Ga verder naar jouw gegevens",
	},
	continueP3: {
		en: "Confirm & pay",
		nl: "Bevestig & betaal",
	},
	selectLanguage: {
		en: "Select language",
		nl: "Selecteer taal",
	},
	dutch: {
		en: "Dutch",
		nl: "Nederlands",
	},
	english: {
		en: "English",
		nl: "Engels",
	},
	selectPlayers: {
		en: "Select number of players",
		nl: "Kies aantal spelers",
	},
	headerP1: {
		en: "Choose your room",
		nl: "Kies je escaperoom",
	},
	headerP2: {
		en: "Choose when and with how many people you're playing",
		nl: "Kies wanneer je komt en met hoeveel mensen",
	},
	headerP3: {
		en: "Enter your details",
		nl: "Vul je gegevens in",
	},
	firstName: {
		en: "First name",
		nl: "Voornaam",
	},
	lastName: {
		en: "Last name",
		nl: "Achternaam",
	},
	email: {
		en: "Email address",
		nl: "Emailadres",
	},
	phone: {
		en: "Phone number",
		nl: "Telefoon",
	},
	secureCheckout: {
		en: "Secure checkout",
		nl: "Beveiligde checkout",
	},
	errorHeader: {
		en: "Oh no, something's gone wrong",
		nl: "Oh nee, er is iets misgegaan",
	},
	errorMessage: {
		en: "Try again, or if nothing else works, please contact us.",
		nl: "Probeer het nog eens, en als niet werkt, neem dan contact op.",
	},
	slotAvailable: {
		en: "Open",
		nl: "Vrij",
	},
	slotSoldout: {
		en: "Sold out",
		nl: "Vol",
	},
	couponLabel: {
		en: "Got a coupon code?",
		nl: "Kortingscode?",
	},
	couponButton: {
		en: "Verify",
		nl: "Invoeren",
	},
	couponInvalid: {
		en: "Sorry, this code is not valid",
		nl: "Sorry, deze code is niet geldig",
	},
	couponValid: {
		en: "has been successfully applied",
		nl: "is toegepast",
	},
	progress1: {
		en: "Choose your room",
		nl: "Kies je escaperoom",
	},
	progress2: {
		en: "Pick a date & time",
		nl: "Kies datum & tijd",
	},
	progress3: {
		en: "Enter your details",
		nl: "Voer je gegevens in",
	},
	progress4: {
		en: "Pay",
		nl: "Betaal",
	},
	sidebarSummary: {
		en: "Order summary",
		nl: "Order overzicht",
	},
	summary: {
		en: "Summary",
		nl: "Overzicht",
	},
	collapse: {
		en: "Collapse",
		nl: "Invouwen",
	},
	expand: {
		en: "Show more and enter coupon code",
		nl: "Meer info en coupon invoeren",
	},
	date: {
		en: "Date",
		nl: "Datum",
	},
	at: {
		en: "at",
		nl: "om",
	},
	prev: {
		en: "Prev",
		nl: "Vorige",
	},
	next: {
		en: "Next",
		nl: "Volgende",
	},
	time: {
		en: "Time",
		nl: "Tijd",
	},
	players: {
		en: "Number of players",
		nl: "Aantal spelers",
	},
	language: {
		en: "Language",
		nl: "Taal",
	},
	pricePerson: {
		en: "Price per person",
		nl: "Prijs per persoon",
	},
	total: {
		en: "Total",
		nl: "Totaal",
	},
	coupon: {
		en: "If you have a coupon code, you can enter it in the next step.",
		nl: "Als je een kortingscode hebt, kun je die in de volgende stap invullen."
	},
	regular: {
		en: "Regular",
		nl: "Normaal"
	},
	offPeak: {
		en: "Off peak",
		nl: "Daluren"
	},
	freeBooking: {
		en: "Booking made successfully. No payment required. We've sent you a confirmation email. Be sure to check the spam folder if nothing arrives.",
		nl: "Boeking gemaakt. Geen betaling nodig. We hebben je een bevestigingsmailtje gestuurd. Mocht je niets ontvangen, check dan even je spam folder."
	},
	lastMinute1: {
		en: 'Last minute reservation?',
		nl: 'Last minute reserveren?',
	},
	lastMinute2: {
		en: 'Call +31 (0)20 210 1302',
		nl: 'Bel +31 (0)20 210 1302',
	},
	lockedDownNote: {
		en: 'Note: times are Amsterdam/NL time (CET/CEST)',
		nl: 'NB: tijden zijn Nederlandse tijd (MET/MEZT)',
	},
}