import { RECEIVE_RATINGS } from '../actions'

export const DEFAULT_RATINGS = 'defaultRatings'

let defaultRatings = {
    EscaperoomsNL: { total: 0, rating: 0 },
    Facebook: { total: 0, rating: 0 },
    Google: { total: 0, rating: 0 },
    Tripadvisor: { total:0, rating:0 },
}

let defaultState = {
    [DEFAULT_RATINGS]: defaultRatings
}

export default function ratings(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_RATINGS:
            const { ratings } = action.payload
            return Object.assign({}, state, {
                ratings: ratings
            });

        default:
            return state
    }
}